import React, { useState, useEffect } from "react"; // Import useState and useEffect
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../utils/firebase"; // Firebase authentication
import { fetchMovies } from "../utils/api"; // TMDB API function
import { useTheme } from "../components/ThemeContext"; // Custom theme context
import {
  getAfterCreditsData,
  saveAfterCreditsInfo,
  checkIfAdmin,
  checkIfSiteOwner,
  fetchAllUsers,
} from "../utils/auth"; // Firestore functions
import {
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const AdminPage = ({ adsEnabled, setAdsEnabled }) => {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMovies, setSelectedMovies] = useState([]);
  const [afterCreditScene1, setAfterCreditScene1] = useState("unknown");
  const [afterCreditScene2, setAfterCreditScene2] = useState("unknown");

  const [isAdmin, setIsAdmin] = useState(false);
  const [isSiteOwner, setIsSiteOwner] = useState(false);
  const [users, setUsers] = useState([]); // Hold all users
  const [searchUsers, setSearchUsers] = useState(""); // Search input state
  const [selectedUsers, setSelectedUsers] = useState([]); // Selected users state
  const [loadingUsers, setLoadingUsers] = useState(true); // Loading state for users
  const [adminUsers, setAdminUsers] = useState([]);
  const [searchAdminUsers, setSearchAdminUsers] = useState("");
  const [loadingAdminUsers, setLoadingAdminUsers] = useState(true);

  const { isDarkMode, setIsDarkMode } = useTheme();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const toggleAds = async () => {
    try {
      const newStatus = !adsEnabled; // Toggle the status
      const docRef = doc(db, "settings", "ads");
      await updateDoc(docRef, { enabled: newStatus }); // Update Firestore
      setAdsEnabled(newStatus); // Update local state
    } catch (error) {
      alert("Failed to toggle ads. Please try again.");
    }
  };

  useEffect(() => {
    const fetchPreferences = async (user) => {
      if (user) {
        const isAdminUser = await checkIfAdmin(user.email); // Fetch admin status
        setIsAdmin(isAdminUser); // Set state for admin status

        const isOwner = await checkIfSiteOwner(user.email); // Check if site owner
        setIsSiteOwner(isOwner); // Set site owner status

        if (!isAdminUser) {
          navigate("/"); // Redirect to homepage if not admin
        }
      } else {
        navigate("/"); // Redirect if no user is logged in
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchPreferences(user);
      } else {
        navigate("/"); // Redirect if no user is logged in
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Update page title and meta info, also fetch all users when component mounts
  useEffect(() => {
    // Update the document title
    document.title = "Admin Dashboard - AfterCreds";

    // Handle meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Admin dashboard for managing users, ads, and movies on AfterCreds.";

    // Handle Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.content = "Admin Dashboard - AfterCreds";

    // Handle Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.content =
      "Access the admin dashboard to manage users, movies, and advertisements for AfterCreds.";

    // Handle Open Graph URL
    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      document.head.appendChild(ogUrl);
    }
    ogUrl.content = "https://www.aftercreds.com/admin";

    // Fetch users and admin users when the component mounts
    const fetchUsers = async () => {
      setLoadingUsers(true);
      try {
        const allUsers = await fetchAllUsers();
        setUsers(allUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
      setLoadingUsers(false);
    };

    const fetchAdminUsers = async () => {
      setLoadingAdminUsers(true);
      try {
        const allUsers = await fetchAllUsers();
        const adminUsersList = allUsers.filter((user) => user.isAdmin); // Filter admin users
        setAdminUsers(adminUsersList);
      } catch (error) {
        console.error("Error fetching admin users:", error);
      }
      setLoadingAdminUsers(false);
    };

    fetchUsers();
    fetchAdminUsers();

    return () => {
      // Reset metadata to default values on unmount
      if (metaDescription) {
        metaDescription.content =
          "A movie website where users can browse their favorite movies and see if they have post-credit scenes.";
      }
      if (ogTitle) {
        ogTitle.content = "AfterCreds - Movie Database";
      }
      if (ogDescription) {
        ogDescription.content =
          "Browse and view the latest movies for information on post-credit scenes.";
      }
      if (ogUrl) {
        ogUrl.content = "https://www.aftercreds.com";
      }
    };
  }, []);

  // Fetch the current ad status from Firestore on component mount
  useEffect(() => {
    const fetchAdStatus = async () => {
      try {
        const docRef = doc(db, "settings", "ads");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAdsEnabled(data.enabled); // Update the adsEnabled state from Firestore
        }
      } catch (error) {
        console.error("Error fetching ad status:", error);
      }
    };

    fetchAdStatus(); // Call the fetch function when the component mounts
  }, [setAdsEnabled]);

  // Filter users based on search input
  const filteredUsers = users.filter((user) =>
    user?.id?.toLowerCase().includes(searchUsers.toLowerCase())
  );

  // Filter users based on search input for admin column
  const filteredAdminUsers = adminUsers.filter((user) =>
    user?.id?.toLowerCase().includes(searchAdminUsers.toLowerCase())
  );

  // Handle user selection from the list
  const handleSelectUser = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  // Promote selected users to admin
  const promoteToAdmin = async () => {
    try {
      const usersToPromote = selectedUsers;
      for (const userId of usersToPromote) {
        const userDocRef = doc(db, "users", userId); // Use Firestore's doc function
        await updateDoc(userDocRef, {
          isAdmin: true,
        });
      }
      alert("Selected users have been promoted to admin.");
      // Refresh users list
      const updatedUsers = await fetchAllUsers();
      setUsers(updatedUsers);
      setAdminUsers(updatedUsers.filter((user) => user.isAdmin));
      setSelectedUsers([]); // Clear selected users
    } catch (error) {
      console.error("Error promoting users:", error);
    }
  };

  // Demote selected admin users
  const demoteFromAdmin = async () => {
    try {
      const usersToDemote = selectedUsers;
      for (const userId of usersToDemote) {
        const userDocRef = doc(db, "users", userId); // Use Firestore's doc function
        await updateDoc(userDocRef, {
          isAdmin: false,
        });
      }
      alert("Selected admin users have been demoted.");
      // Refresh users list
      const updatedUsers = await fetchAllUsers();
      setUsers(updatedUsers);
      setAdminUsers(updatedUsers.filter((user) => user.isAdmin));
      setSelectedUsers([]); // Clear selected users
    } catch (error) {
      console.error("Error demoting users:", error);
    }
  };

  const fetchMovieList = async (query) => {
    setLoading(true);
    try {
      const response = await fetchMovies(query); // Fetch movies from TMDB
      const movieData = await Promise.all(
        response.data.results.map(async (movie) => {
          // Get after credits data or provide default values if it doesn't exist
          const afterCreditsData = (await getAfterCreditsData(movie.id)) || {
            afterCreditScene1: "unknown",
            afterCreditScene2: "unknown",
          };
          return { ...movie, ...afterCreditsData }; // Combine TMDB and Firestore data
        })
      );
      setMovies(movieData.slice(0, 30)); // Limit results to 30 movies
    } catch (error) {
      console.error("Error fetching movies:", error);
    }
    setLoading(false);
  };

  // Replace this call in the debounce effect:
  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        fetchMovieList(searchTerm); // Call the renamed function
      }, 500); // 0.5 seconds debounce time
      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);

  // Function to handle selecting movies
  const handleSelectMovie = (movie) => {
    setSelectedMovies(
      (prevSelected) =>
        prevSelected.some((m) => m.id === movie.id)
          ? prevSelected.filter((m) => m.id !== movie.id)
          : [...prevSelected, movie] // Store entire movie object, not just ID
    );
  };

  const applyChanges = async () => {
    try {
      for (const movie of selectedMovies) {
        if (!movie.id) {
          console.error("Invalid movie object: ", movie);
          continue; // Skip invalid entries without an ID
        }

        const movieIdString = movie.id.toString();
        const movieTitleString = movie.title.toString();

        await saveAfterCreditsInfo(
          movieIdString,
          movieTitleString,
          afterCreditScene1,
          afterCreditScene2
        );
      }

      alert("Changes applied to selected movies.");
      setSelectedMovies([]); // Clear the selected movies after applying changes
    } catch (error) {
      console.error("Error applying changes: ", error);
    }
  };

  const clearSelectedMovies = () => setSelectedMovies([]); // Clear selected movies

  return (
    <div className="font-custom bg-gray-200 dark:bg-gray-900 min-h-screen pt-20">
      {isAdmin && (
        <div className="container mx-auto p-8">
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-6">
            <h1 className="text-3xl font-semibold text-gray-900 dark:text-white">
              Admin Dashboard
            </h1>
            <div className="w-full sm:w-auto text-md px-4 py-3 rounded-lg mt-4 lg:mt-0 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 shadow-lg">
              Total Users: {users.length}
            </div>
          </div>

          {/* Toggle Ads button */}
          {isSiteOwner && (
            <div className="flex p-4 border border-gray-300 dark:border-gray-600 shadow-lg rounded-lg mb-6 gap-4 bg-white dark:bg-gray-800 justify-center">
              <div className="flex space-x-4 justify-center">
                <p className="text-center text-gray-900 dark:text-white my-auto whitespace-nowrap w-full max-w-48">
                  Ads are currently {adsEnabled ? "Enabled" : "Disabled"}
                </p>
                {adsEnabled ? (
                  <button
                    onClick={toggleAds}
                    aria-label="Disable ads"
                    className="
      w-32 sm:w-52 py-2 px-6 rounded-lg
      text-white text-lg font-semibold
      bg-gradient-to-r from-red-600 to-red-500 hover:from-red-500 hover:to-red-400 active:from-red-700 active:to-red-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg focus:outline-none focus:ring-4 focus:ring-red-300
    "
                  >
                    Disable Ads
                  </button>
                ) : (
                  <button
                    onClick={toggleAds}
                    aria-label="Enable ads"
                    className="
      w-32 sm:w-52 py-2 px-6 rounded-lg
      text-white text-lg font-semibold
      bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 active:from-blue-700 active:to-blue-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-300
    "
                  >
                    Enable Ads
                  </button>
                )}
              </div>
            </div>
          )}

          {isSiteOwner && (
            <div className="grid grid-cols-12 gap-4 lg:grid-cols-12 lg:gap-4">
              {/* First Column - All Users */}
              <div className="col-span-12 lg:col-span-5 border border-gray-300 dark:border-gray-600 p-4 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2
                    className={`text-xl font-semibold ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    All Users
                  </h2>
                  <span
                    className={`text-sm font-semibold ${
                      isDarkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Total: {filteredUsers.length}
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Search users..."
                  aria-label="Search users by ID"
                  value={searchUsers}
                  onChange={(e) => setSearchUsers(e.target.value)}
                  className="w-full py-2 px-6 rounded-lg text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-green-300 transition-all duration-300 ease-in-out shadow-md placeholder-gray-400 mb-4"
                />

                {loadingUsers ? (
                  <p className="text-center text-gray-900 dark:text-white">
                    Loading users...
                  </p>
                ) : (
                  <ul className="overflow-y-auto max-h-48">
                    {" "}
                    {/* Reduced height */}
                    {filteredUsers.length > 0 ? (
                      filteredUsers.slice(0, 50).map((user) => (
                        <li
                          key={user.id}
                          className={`mb-2 flex justify-between items-center p-2 rounded-lg transition ${
                            selectedUsers.includes(user.id)
                              ? "bg-gray-200 dark:bg-gray-600"
                              : "bg-gray-100 dark:bg-gray-700"
                          } hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer`}
                          onClick={() => handleSelectUser(user.id)}
                        >
                          <label className="flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              checked={selectedUsers.includes(user.id)}
                              onChange={() => handleSelectUser(user.id)}
                              className="mr-2"
                            />
                            <span className="text-gray-900 dark:text-white">
                              {user.id}
                            </span>
                          </label>
                        </li>
                      ))
                    ) : (
                      <p className="text-center text-gray-900 dark:text-white">
                        No users found.
                      </p>
                    )}
                  </ul>
                )}
              </div>

              {/* Buttons for mobile view */}
              <div className="col-span-12 flex justify-center space-x-6 lg:hidden my-4">
                {/* Promote Button */}
                <button
                  onClick={() => promoteToAdmin()}
                  aria-label="Promote selected users to admin"
                  className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 p-3 rounded-md"
                >
                  <ArrowLongDownIcon className="w-6 h-6 text-gray-900 dark:text-white" />
                </button>

                {/* Demote Button */}
                <button
                  onClick={() => demoteFromAdmin()}
                  aria-label="Demote selected users from admin"
                  className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 p-3 rounded-md"
                >
                  <ArrowLongUpIcon className="w-6 h-6 text-gray-900 dark:text-white" />
                </button>
              </div>

              {/* Button Column for desktop */}
              <div className="col-span-2 hidden lg:flex flex-col justify-center items-center space-y-4">
                {/* Promote Button */}
                <button
                  onClick={() => promoteToAdmin()}
                  aria-label="Promote selected users to admin"
                  className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 p-3 rounded-md border border-gray-300 dark:border-gray-600"
                >
                  <ArrowLongRightIcon className="w-6 h-6 text-gray-900 dark:text-white" />
                </button>

                {/* Demote Button */}
                <button
                  onClick={() => demoteFromAdmin()}
                  aria-label="Demote selected users from admin"
                  className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 p-3 rounded-md border border-gray-300 dark:border-gray-600"
                >
                  <ArrowLongLeftIcon className="w-6 h-6 text-gray-900 dark:text-white" />
                </button>
              </div>

              {/* Second Column - Admin Users */}
              <div className="col-span-12 lg:col-span-5 border border-gray-300 dark:border-gray-600 p-4 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2
                    className={`text-xl font-semibold ${
                      isDarkMode ? "text-white" : "text-gray-900"
                    }`}
                  >
                    Admin Users
                  </h2>
                  <span
                    className={`text-sm font-semibold ${
                      isDarkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Total: {filteredAdminUsers.length}
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Search admin users..."
                  aria-label="Search users by ID"
                  value={searchAdminUsers}
                  onChange={(e) => setSearchAdminUsers(e.target.value)}
                  className="w-full py-2 px-6 rounded-lg text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-green-300 transition-all duration-300 ease-in-out shadow-md placeholder-gray-400 mb-4"
                />
                {loadingAdminUsers ? (
                  <p className="text-center text-gray-900 dark:text-white">
                    Loading admin users...
                  </p>
                ) : (
                  <ul className="overflow-y-auto max-h-48">
                    {" "}
                    {/* Reduced height */}
                    {filteredAdminUsers.length > 0 ? (
                      filteredAdminUsers.map((user) => (
                        <li
                          key={user.id}
                          className={`mb-2 flex justify-between items-center p-2 rounded-lg transition ${
                            selectedUsers.includes(user.id)
                              ? "bg-gray-200 dark:bg-gray-600"
                              : "bg-gray-100 dark:bg-gray-700"
                          } hover:bg-gray-200 dark:hover:bg-gray-600 cursor-pointer`}
                          onClick={() => handleSelectUser(user.id)}
                        >
                          <label className="flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              checked={selectedUsers.includes(user.id)}
                              onChange={() => handleSelectUser(user.id)}
                              className="mr-2"
                            />
                            <span
                              className={
                                isDarkMode ? "text-white" : "text-gray-900"
                              }
                            >
                              {user.id}
                            </span>
                          </label>
                        </li>
                      ))
                    ) : (
                      <p className="text-center text-gray-900 dark:text-white">
                        No admin users found.
                      </p>
                    )}
                  </ul>
                )}
              </div>
            </div>
          )}

          {isSiteOwner && (
            <div className="my-8">
              <hr className="block border-gray-300 dark:border-gray-700" />
            </div>
          )}

          {/* Toolbar for bulk editing */}
          <div className="p-4 border border-gray-300 dark:border-gray-600 shadow-lg rounded-lg mb-6 grid grid-cols-1 gap-4 bg-white dark:bg-gray-800">
            <div className="flex space-x-4 justify-center">
              {/* Dropdowns for after-credits scenes */}
              <div className="w-full max-w-48">
                <label
                  className={`block mb-2 font-semibold ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Mid Scene
                </label>
                <select
                  value={afterCreditScene1}
                  onChange={(e) => setAfterCreditScene1(e.target.value)}
                  className={`p-2 border rounded-lg w-full ${
                    isDarkMode
                      ? "bg-gray-700 border-gray-600 text-white"
                      : "bg-white border-gray-300 text-gray-900"
                  }`}
                >
                  <option value="unknown">Unknown</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>

              <div className="w-full max-w-48">
                <label
                  className={`block mb-2 font-semibold ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  End Scene
                </label>
                <select
                  value={afterCreditScene2}
                  onChange={(e) => setAfterCreditScene2(e.target.value)}
                  className={`p-2 border rounded-lg w-full ${
                    isDarkMode
                      ? "bg-gray-700 border-gray-600 text-white"
                      : "bg-white border-gray-300 text-gray-900"
                  }`}
                >
                  <option value="unknown">Unknown</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex space-x-4 justify-center">
              <button
                onClick={applyChanges}
                aria-label="Apply movie changes"
                className="w-full max-w-48 py-2 px-6 rounded-lg text-white font-semibold bg-gradient-to-r from-green-600 to-green-500 hover:from-green-500 hover:to-green-400 active:from-green-700 active:to-green-600 transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95 shadow-lg focus:outline-none focus:ring-4 focus:ring-green-300"
              >
                Apply Changes
              </button>

              <button
                onClick={clearSelectedMovies}
                aria-label="Clear selected movies"
                className="w-full max-w-48 py-2 px-6 rounded-lg text-white font-semibold bg-gradient-to-r from-red-600 to-red-500 hover:from-red-500 hover:to-red-400 active:from-red-700 active:to-red-600 transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95 shadow-lg focus:outline-none focus:ring-4 focus:ring-red-300"
              >
                Clear Selected
              </button>
            </div>
          </div>

          {/* Movie List Columns */}
          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-8 gap-6">
            {/* Search bar and available movies */}
            <div className="w-full lg:w-1/2 order-last lg:order-none border border-gray-300 dark:border-gray-600 rounded-lg p-4 bg-white dark:bg-gray-800">
              <input
                type="text"
                value={searchTerm}
                aria-label="Search movies"
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search movies..."
                className="w-full py-2 px-6 rounded-lg text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-green-300 transition-all duration-300 ease-in-out shadow-md placeholder-gray-400 mb-4"
              />
              {loading ? (
                <p
                  className={`text-center ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  Loading...
                </p>
              ) : movies.length === 0 ? (
                <p className="text-center text-gray-900 dark:text-white">
                  Begin searching for movies...
                </p>
              ) : (
                <ul className="max-h-96 overflow-y-auto">
                  {movies.map((movie) => (
                    <li
                      key={movie.id}
                      onClick={() => handleSelectMovie(movie)}
                      className={`mb-2 flex justify-between items-center border border-gray-300 dark:border-gray-600 rounded-lg p-2 ${
                        selectedMovies.some((m) => m.id === movie.id)
                          ? "bg-gray-200 dark:bg-gray-700"
                          : ""
                      }`}
                    >
                      <label
                        className={`flex items-center ${
                          isDarkMode ? "text-white" : "text-gray-900"
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedMovies.some(
                            (m) => m.id === movie.id
                          )}
                          onChange={() => handleSelectMovie(movie)}
                          className="mr-2"
                        />
                        {movie.title}
                      </label>
                      <div className="flex space-x-2">
                        {movie.afterCreditScene1 === "yes" ? (
                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                        ) : movie.afterCreditScene1 === "no" ? (
                          <XCircleIcon className="w-6 h-6 text-red-500" />
                        ) : (
                          <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
                        )}
                        {movie.afterCreditScene2 === "yes" ? (
                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                        ) : movie.afterCreditScene2 === "no" ? (
                          <XCircleIcon className="w-6 h-6 text-red-500" />
                        ) : (
                          <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Selected movies */}
            <div className="w-full lg:w-1/2 order-first lg:order-none border border-gray-300 dark:border-gray-600 rounded-lg p-4 bg-white dark:bg-gray-800">
              <h2
                className={`text-2xl font-semibold mb-4 ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Selected Movies
              </h2>
              <ul className="max-h-96 overflow-y-auto">
                {selectedMovies.length === 0 ? (
                  <p className="text-center text-gray-900 dark:text-white">
                    No movies selected.
                  </p>
                ) : (
                  selectedMovies.map((movie) => (
                    <li
                      key={movie.id}
                      onClick={() => handleSelectMovie(movie)}
                      className={`mb-2 flex justify-between items-center border border-gray-300 dark:border-gray-600 rounded-lg p-2 ${
                        selectedMovies.some((m) => m.id === movie.id)
                          ? "bg-gray-200 dark:bg-gray-700"
                          : ""
                      }`}
                    >
                      <label
                        className={`flex items-center ${
                          isDarkMode ? "text-white" : "text-gray-900"
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedMovies.some(
                            (m) => m.id === movie.id
                          )}
                          onChange={() => handleSelectMovie(movie)}
                          className="mr-2"
                        />
                        {movie.title}
                      </label>

                      {/* Display icons based on after-credits data */}
                      <div className="flex space-x-2">
                        {movie.afterCreditScene1 === "yes" ? (
                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                        ) : movie.afterCreditScene1 === "no" ? (
                          <XCircleIcon className="w-6 h-6 text-red-500" />
                        ) : (
                          <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
                        )}
                        {movie.afterCreditScene2 === "yes" ? (
                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                        ) : movie.afterCreditScene2 === "no" ? (
                          <XCircleIcon className="w-6 h-6 text-red-500" />
                        ) : (
                          <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
                        )}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
