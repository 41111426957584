import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline"; // Import outlined heart icon
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid"; // Import solid heart icon
import {
  saveMovieToFavorites,
  removeMovieFromFavorites,
  getUserFavorites,
} from "../utils/auth"; // Import functions to save and remove liked movies
import { auth } from "../utils/firebase"; // Firebase auth
import BannerAd from "../components/BannerAd";
import LargerAd from "../components/LargerAd";

const Home = ({ searchTerm }) => {
  const API_KEY = process.env.REACT_APP_TMDB_API_KEY; // Access the API key from environment variables
  const [movies, setMovies] = useState([]);
  const [timer, setTimer] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [likedMovies, setLikedMovies] = useState([]); // Ensure likedMovies is initialized as an array

  const user = auth.currentUser;

  useEffect(() => {
    // Update the document title
    document.title = "Home - AfterCreds";

    // Handle meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Home page of AfterCreds.com.";

    // Handle Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.content = "Home - AfterCreds";

    // Handle Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.content =
      "Explore the latest movies and features of AfterCreds.";

    // Handle Open Graph URL
    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      document.head.appendChild(ogUrl);
    }
    ogUrl.content = "https://www.aftercreds.com";

    // Inject structured data for SEO purposes
    if (movies.length > 0) {
      const structuredData = {
        "@context": "https://schema.org",
        "@type": "MovieCollection",
        name: "Recent Movies",
        movie: movies.map((movie) => ({
          "@type": "Movie",
          name: movie.title,
          url: `https://www.aftercreds.com/movie/${movie.title
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]/g, "")}/${movie.id}`,
          image: `https://image.tmdb.org/t/p/w500/${movie.poster_path}`,
          datePublished: movie.release_date,
          description: movie.overview,
        })),
      };

      const scriptTag = document.createElement("script");
      scriptTag.type = "application/ld+json";
      scriptTag.textContent = JSON.stringify(structuredData);
      document.head.appendChild(scriptTag);

      return () => {
        // Clean up metadata and structured data on unmount or movie change
        document.title = "AfterCreds - Movie Database";
        metaDescription.content =
          "A movie website where users can browse their favorite movies and see if they have post-credit scenes.";
        ogTitle.content = "AfterCreds - Movie Database";
        ogDescription.content =
          "Browse and view the latest movies for information on post-credit scenes.";
        ogUrl.content = "https://www.aftercreds.com";

        document.head.removeChild(scriptTag);
      };
    }
  }, [movies]); // Runs whenever the movies array changes

  // Fetch movies with a delay when searching
  useEffect(() => {
    const fetchMoviesWithCertifications = async (moviesArray) => {
      // Fetch certification for each movie
      const moviesWithCertifications = await Promise.all(
        moviesArray.map(async (movie) => {
          try {
            const releaseDatesResponse = await fetch(
              `https://api.themoviedb.org/3/movie/${movie.id}/release_dates?api_key=${API_KEY}`
            );
            const releaseDatesData = await releaseDatesResponse.json();

            const usRelease = releaseDatesData.results.find(
              (release) => release.iso_3166_1 === "US"
            );

            // Try to find the correct release type for certification (type 3: theatrical)
            const certification =
              usRelease?.release_dates.find((release) => release.type === 3)
                ?.certification || "NR"; // Default to Unrated if not found

            return { ...movie, certification }; // Append the certification to the movie object
          } catch (error) {
            console.error(
              `Error fetching certification for movie ${movie.id}:`,
              error
            );
            return { ...movie, certification: "NR" }; // Default to Unrated if an error occurs
          }
        })
      );
      return moviesWithCertifications;
    };

    if (searchTerm) {
      clearTimeout(timer);
      setLoading(true);
      setTimer(
        setTimeout(async () => {
          try {
            const response = await fetch(
              `https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&query=${searchTerm}&language=en-US&region=US&sort_by=popularity.desc&include_adult=false`
            );
            const data = await response.json();

            const moviesWithCertifications =
              await fetchMoviesWithCertifications(data.results);
            setMovies(moviesWithCertifications);
          } catch (error) {
            console.error("Error fetching search results:", error);
          }
          setLoading(false);
        }, 1000)
      );
    } else {
      const fetchRecentMovies = async () => {
        setLoading(true);
        try {
          // Fetch the first and second page of now playing movies
          const responsePage1 = await fetch(
            `https://api.themoviedb.org/3/movie/now_playing?api_key=${API_KEY}&language=en-US&region=US&sort_by=popularity.desc&include_adult=false&page=1`
          );
          const dataPage1 = await responsePage1.json();

          const responsePage2 = await fetch(
            `https://api.themoviedb.org/3/movie/now_playing?api_key=${API_KEY}&language=en-US&region=US&sort_by=popularity.desc&include_adult=false&page=2`
          );
          const dataPage2 = await responsePage2.json();

          // Combine results from both pages
          const allMovies = [...dataPage1.results, ...dataPage2.results]
            .filter((movie) => movie.release_date && !movie.adult)
            .reduce((unique, movie) => {
              if (!unique.some((m) => m.id === movie.id)) {
                unique.push(movie);
              }
              return unique;
            }, [])
            .sort((a, b) => new Date(b.release_date) - new Date(a.release_date))
            .slice(0, 30);

          const moviesWithCertifications = await fetchMoviesWithCertifications(
            allMovies
          );
          setMovies(moviesWithCertifications);
        } catch (error) {
          console.error("Error fetching recent movies:", error);
        }
        setLoading(false);
      };

      fetchRecentMovies();
    }
  }, [searchTerm]);

  // Fetch liked movies for the user when the component mounts
  useEffect(() => {
    const fetchLikedMovies = async () => {
      if (user) {
        const likedMoviesData = await getUserFavorites(user.email); // Use email here
        setLikedMovies(Array.isArray(likedMoviesData) ? likedMoviesData : []); // Ensure likedMovies is an array
      }
    };
    fetchLikedMovies();
  }, [user]);

  // Handle like or unlike a movie
  const handleLike = async (movie) => {
    if (!user) {
      alert("You need to be logged in to like a movie.");
      return;
    }

    const isMovieLiked = likedMovies.includes(movie.id);

    if (isMovieLiked) {
      await removeMovieFromFavorites(user.email, movie.id); // Use email here
      setLikedMovies(likedMovies.filter((id) => id !== movie.id));
    } else {
      await saveMovieToFavorites(user.email, movie.id); // Use email here
      setLikedMovies([...likedMovies, movie.id]);
    }
  };

  // Utility function to format date as mm-dd-yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="font-custom min-h-screen bg-gray-200 dark:bg-gray-900">
      {/* Header Section */}
      {!searchTerm && (
        <div className="container mx-auto pt-32 text-center">
          <h1 className="text-4xl font-bold text-gray-800 dark:text-white mb-4">
            Welcome to <span className="text-green-400">aftercreds</span>
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Your go-to platform for discovering movies and tracking
            after-credits scenes. Browse the latest releases and keep track of
            your favorite movies.
          </p>
        </div>
      )}

      {/* Movie Cards */}
      <div
        className={`container mx-auto py-16 px-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 ${
          !searchTerm ? "" : "pt-32"
        }`}
      >
        {loading ? (
          // Display placeholder cards when loading
          Array(10)
            .fill(0)
            .map((_, index) => (
              <div key={index} className="animate-pulse">
                <div className="bg-gray-300 dark:bg-gray-700 h-64 rounded-lg"></div>
                <div className="mt-2 h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"></div>
                <div className="mt-1 h-4 bg-gray-300 dark:bg-gray-700 rounded w-1/2"></div>
              </div>
            ))
        ) : movies.length === 0 ? (
          // Display a message if no movies are found
          <p className="pt-16 text-center text-gray-700 dark:text-gray-300 w-full col-span-5">
            No movies found.
          </p>
        ) : (
          // Display actual movie cards when data is loaded
          movies.map(
            (movie, index) =>
              movie.poster_path && (
                <React.Fragment key={movie.id}>
                  <div key={movie.id} className="relative">
                    <Link
                      to={`/movie/${movie.title
                        .replace(/\s+/g, "-")
                        .replace(/[^\w\-]/g, "")}/${movie.id}`}
                    >
                      <div className="bg-gray-50 dark:bg-gray-700 rounded-lg shadow-md overflow-hidden transform hover:scale-105 hover:border-green-400 hover:border-2 transition duration-300 ease-in-out">
                        <img
                          src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`}
                          alt={movie.title}
                          className="w-full h-full object-cover"
                        />

                        <div className="p-4">
                          <h3 className="text-md font-medium text-gray-800 dark:text-gray-200">
                            {movie.title.length > 23
                              ? `${movie.title.substring(0, 23)}...`
                              : movie.title}
                          </h3>
                          <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                            {" "}
                            {movie.release_date
                              ? formatDate(movie.release_date)
                              : "N/A"}
                            {movie.release_date && movie.certification && (
                              <span> &bull; {movie.certification}</span>
                            )}
                          </p>

                          {/* <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                            {movie.overview
                              ? `${movie.overview.substring(0, 40)}...`
                              : "No description available."}
                          </p> */}
                        </div>
                      </div>
                    </Link>

                    {/* Like Button */}
                    <button
                      className="absolute top-2 right-2 focus:outline-none"
                      onClick={() => handleLike(movie)}
                    >
                      {Array.isArray(likedMovies) &&
                      likedMovies.includes(movie.id) ? (
                        <HeartIconSolid className="w-10 h-10 text-red-500" />
                      ) : (
                        <HeartIconOutline className="w-10 h-10 text-gray-300 dark:text-gray-500" />
                      )}
                    </button>
                  </div>

                  {/* Larger Ad after every 15th movie card */}
                  {index % 15 === 14 && <LargerAd />}
                </React.Fragment>
              )
          )
        )}
      </div>
      <BannerAd />
    </div>
  );
};

export default Home;
